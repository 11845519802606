import { GetServerSidePropsContext } from 'next';

import { Locale } from '@silvertours/back-domain-sitegroup';
import { LandingPageServerSideRequest } from '@silvertours/back-runtime-landingpages';
import { LandingPageLegacy } from '@silvertours/front-pages';

export const getServerSideProps = (nextContext: GetServerSidePropsContext) => {
  const handler = new LandingPageServerSideRequest(Locale.French.value);

  return handler.getServerSideProps(nextContext);
};

export default LandingPageLegacy.LandingPage;
